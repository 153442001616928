@import "../../../assets/scss/variables";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/mixins";

.SearchBar {
  background-color: $darkgray;
  box-sizing: border-box;
  font-family: Poppins;
  padding-top: 80px;
  padding-left: 17px;
  display: block;
  width: 100%;

  @include breakpoint(lg) {
    background-color: white;
  }

  @include breakpoint(xxl) {
    padding-top: 100px;
  }

  .search-field {
    border-bottom: 1px solid white;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;

    @include breakpoint(lg) {
      align-items: center;
      flex-direction: row;
      padding: 18px 0 12px;
    }

    .search-field-title{
      display: flex;
      flex-direction: column;

      @include breakpoint(lg) {
        align-items: center;
        flex-direction: row;
      }

    h2 {
      color: white;
      font-size: pxToRem(17px);
      font-weight: 800;
      letter-spacing: 0.38px;
      margin: 8px;
      text-transform: uppercase;

      @include breakpoint(lg) {
        color: $black;
        font-size: pxToRem(19px);
        padding: 5px 24px 0;
      }
    }

    .tagline{
      font-size: pxToRem(15px);
      font-weight: 400;
      padding: 0 10px 0;

      @include breakpoint(lg) {
        color: black;
        font-size: pxToRem(17px);
        margin: 0;
        padding: 4px 4px 0;
      }
    }
  }

    button {
      background-color: transparent;
      border: none;
      font-weight: 800;
      margin: 8px;
    }

    aside {
      display: flex;
      justify-content: flex-end;

      svg {
        fill: #fff;
        width: 32px;

        @include breakpoint(lg) {
          display: none;
        }
      }

      .desktop-button {
        display: none;

        @include breakpoint(lg) {
          background-color: transparent;
          border: none;
          border-bottom: 3px solid $black;
          box-sizing: border-box;
          display: block;
          cursor: pointer;
          font-size: pxToRem(20px);
          padding: 10px 20px;
        }

        @include breakpoint(xl) {
          font-size: pxToRem(22px);
        }

        @include breakpoint(xxl) {
          font-size: pxToRem(25px);
        }
      }
    }
  }

  .selected-tags{
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: pxToRem(11px);
    font-style: italic;
    margin: 5px;
    padding: 8px 10px;
    text-transform: uppercase;

    @include breakpoint(lg) {
      color: $darkgray;
      cursor: pointer;
      font-size: pxToRem(14px);
      margin: 5px 17px;
      padding: 5px 15px 15px;
    }
  }

  .tag-field {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;

    @include breakpoint(lg) {
      display: flex;
      justify-content: flex-start;
      padding: 15px 24px;
    }

    .tag {
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      font-size: pxToRem(12px);
      margin: 5px;
      padding: 8px 18px;
      text-transform: uppercase;

      @include breakpoint(lg) {
        border: 3px solid $darkgray;
        color: $darkgray;
        cursor: pointer;
        font-size: pxToRem(19px);
        font-weight: 800;
        margin: 10px;
        opacity: 0.2;
        padding: 13px 40px;
      }
    }
    .active{
      background-color: #ffffff;
      color: black;

      @include breakpoint(lg) {
        opacity: 1;
      }
    }
  }
}
