$black: #000;
$red: #FF0000;
$wine: #5D0019;
$darkgray: #242424;

$breakpoints: (
  "xs": 400px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1600px,
  "xxxl": 1800px
);
