@import "../../../assets/scss/variables";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.Main {
  background-color: $darkgray;

  @include breakpoint(lg) {
    padding-left: 0;
  }

  article {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0;
      width: 40%;

      @include breakpoint(lg) {
        margin: auto;
        max-width: 260px;
        overflow: hidden;
        position: relative;
        width: 90%;

        &:hover .overlay {
          opacity: 1;
        }
        &:hover .text {
          left: 50%;
          opacity: 1;
          top: 50%;
        }
      }

      @include breakpoint(xl) {
        max-width: 20%;
      }
    }

    .inactive {
      opacity: 0.2;
    }

    .content .overlay {
      position: unset;

      @include breakpoint(lg) {
        background: rgba(245, 40, 40, 0.9);
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.4s ease-in-out 0s;
        width: 100%;
      }
    }

    img {
      padding: 20px 26px 5px 0px;
      width: 138px;

      @include breakpoint(lg) {
        padding: 0;
        width: 100%;
      }
    }

    .text {
      color: white;
      display: flex;
      flex-direction: column;
      position: relative;

      @include breakpoint(lg) {
        left: 50%;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out 0s;
        width: 100%;
      }

      p {
        color: white;
        font-family: Poppins;
        font-size: pxToRem(11px);
        font-weight: 400;
        width: 85%;

        @include breakpoint(lg) {
          font-family: Morganite;
          font-weight: 600;
          font-size: pxToRem(30px);
          letter-spacing: 1.48px;
          margin: 0;
          padding: 0;
        }

        @include breakpoint(xl) {
          font-size: pxToRem(40px);
          padding: 0 25px;
        }

        @include breakpoint(xxl) {
          font-size: pxToRem(60px);
        }
      }

      a {
        text-decoration: none;

        button {
          display: none;

          @include breakpoint(lg) {
            background: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            left: 42%;
            position: relative;
          }

          svg{
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}
