@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"), url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(Poppins, "../../assets/fonts/Poppins-Regular.ttf", 200, normal);
@include font-face(Poppins, "../../assets/fonts/Poppins-Medium.ttf", 400, normal);
@include font-face(Poppins, "../../assets/fonts/Poppins-Bold.ttf", 800, normal);
@include font-face(Morganite, "../../assets/fonts/Morganite-Light.ttf", 200, normal);
@include font-face(Morganite, "../../assets/fonts/Morganite-Medium.ttf", 400, normal);
@include font-face(Morganite, "../../assets/fonts/Morganite-Bold.ttf", 800, normal);
