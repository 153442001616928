@import "../../assets/scss/variables";
@import "../../assets/scss/functions";
@import "../../assets/scss/mixins";
@import "../../assets/scss/fonts";

.Episode {
  background-color: $wine;
  margin-top: 70px;
  padding-bottom: 30px;

  @include breakpoint(xl) {
    display: flex;
    flex-direction: row;
  }

  .title {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(xl) {
      flex-direction: row;
      padding: 0;
      padding-bottom: 30px;
      width: 320px;
    }

    @include breakpoint(xxl) {
      width: 420px;
    }

    h1 {
      font-size: pxToRem(21px);
      letter-spacing: 0.42px;
      text-align: center;
      width: 250px;

      @include breakpoint(xl) {
        font-size: pxToRem(24px);
        padding: 0;
        text-align: left;
      }

      @include breakpoint(xxl) {
        font-size: pxToRem(32px);
      }
    }

    a {
      border-bottom: none;
      .play-icon {
        width: 61px;

        @include breakpoint(xl) {
          bottom: 11%;
          left: 78%;
          width: 60px;
        }

        @include breakpoint(xxl) {
          width: 80px;
        }

        &:hover {
          border: 2px solid black;
          border-radius: 50%;
        }
      }
    }
  }
  div {
    align-items: center;
    box-sizing: border-box;
    color: #ffff;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(xl) {
      align-items: flex-start;
      padding: 128px 50px;
      width: 36%;
    }

    @include breakpoint(xxl) {
      padding: 128px 100px;
      width: 33%;
    }

    img {
      padding: 20px 70px;
      width: 250px;

      @include breakpoint(xl) {
        padding: 0;
        width: 320px;
      }

      @include breakpoint(xxl) {
        padding: 0;
        width: 420px;
      }
    }

    h3 {
      font-size: pxToRem(20px);
      letter-spacing: 0.4px;
      line-height: 25px;
      margin: 0;
      padding: 30px 0 10px;

      @include breakpoint(xl) {
        font-size: pxToRem(26px);
        padding: 0 0 20px;
      }
    }

    p {
      font-family: Poppins;
      font-size: pxToRem(16px);
      font-weight: 200;
      letter-spacing: 0.32px;
      line-height: 35px;
      padding: 10px 21px;
      text-align: center;

      @include breakpoint(xl) {
        font-size: pxToRem(18px);
        padding: 0;
        text-align: left;
      }
    }

    a {
      color: white;
      text-decoration: none;

      @include breakpoint(xl) {
        border-bottom: 1px solid #fff;
      }

      .related-links {
        font-size: pxToRem(14px);
        line-height: normal;
        margin: 0;
        margin-top: 16px;
        padding: 10px 0 10px;

        @include breakpoint(xl) {
          font-size: pxToRem(15px);
          font-weight: 800;
          line-height: 28px;
          width: 100%;
        }
        @include breakpoint(xxl) {
          font-size: pxToRem(18px);
          line-height: 32px;
        }
      }
    }

    iframe {
      height: 400px;
      padding: 30px 21px;
      width: 85%;

      @include breakpoint(xl) {
        height: 625px;
        padding: 0;
        padding-top: 10px;
        width: 380px;
      }
    }

    .EpisodeTags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 17px;

      @include breakpoint(xl) {
        justify-content: normal;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      a {
        border-bottom: none;
        text-decoration: none;
        width: unset;

        button {
          background-color: transparent;
          border: 3px solid #ffff;
          color: #ffff;
          font-family: Poppins;
          font-size: pxToRem(13px);
          font-weight: 600;
          letter-spacing: 0.26px;
          line-height: 19px;
          margin: 5px 5px 7px;
          padding: 9px 15px;
          text-transform: uppercase;

          @include breakpoint(xl) {
            font-size: pxToRem(15px);
            margin: 10px 12px 10px 0px;
            padding: 12px 20px;
          }
          @include breakpoint(xxl) {
            font-size: pxToRem(19px);
          }
        }
      }
    }
  }
  .tags-links {
    padding: 0 17px;

    @include breakpoint(xl) {
      align-items: flex-start;
      padding: 128px 50px;
      width: 36%;
    }

    @include breakpoint(xxl) {
      padding: 128px 100px;
      width: 33%;
    }

    a {
      padding: 0;
      width: 100%;
    }
  }

  .related-link-title {
    padding-top: 30px;
  }
}
