@import "../../../assets/scss/variables";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/mixins";

.Navbar {
  align-items: center;
  background-color:$black;
  box-sizing: border-box;
  color: white;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  @include breakpoint(lg) {
    padding: 0 20px 0 60px;
  }

  @include breakpoint(xl) {
    height: 85px;
  }

  @include breakpoint(xxl) {
    height: 100px;
  }

  img{
    width: 107px;

    @include breakpoint(lg) {
      width: 139px;
    }
  }

  .hamburguer-icon {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    height: 21px;
    justify-content: space-between;
    width: 33px;

    @include breakpoint(lg) {
      display: none;
    }

    span {
      background-color: white;
      height: 3px;
      transform: rotate(0);
      transform-origin: left center;
      transition: 0.6s;
      width: 25px;

      &:nth-child(2) {
        align-self: flex-start;
      }
    }
  }

  .menu-items {
    background-color: black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    left: 0;
    padding: 90px 20px 0;
    position: absolute;
    top: 0;
    transform: translateX(-130%);
    transition: 0.6s;
    width: 100%;
    z-index: -1;

    @include breakpoint(lg) {
      padding-left: 100px;
    }
    

    a {
      color: white;
      font-size: pxToRem(16px);
      margin: 20px 10px;
      text-decoration: none;

      @include breakpoint(lg) {
        font-size: pxToRem(17px);
      }

      @include breakpoint(xl) {
        font-size: pxToRem(18px);
      }

      @include breakpoint(xxl) {
        font-size: pxToRem(20px);
      }
    }

    input{
      display: none;

      @include breakpoint(lg) {
        border: none;
        display: flex;
        font-size: pxToRem(18px);
        padding: 10px 22px;
        width: 30%;

        &::placeholder{
          color: #BDBDBD;
          text-align: center;
          font-weight: 500;
        }

        &:focus{
          border: 2px solid $red;
        }
      }
    }

    @include breakpoint(lg) {
      flex-direction: row;
      justify-content: space-around;
      padding: 0;
      padding-left: 60px;
      position: static;
      transform: translateX(0);
    }
  }

  &.active {
    .hamburguer-icon{
      justify-content: space-between;

      span {
        background-color: white;

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          margin-bottom: 1px;
          transform: rotate(-45deg);
        }
      }
    }

    .menu-items {
      transform: translateX(0);
    }
  }
}
